
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class PackageComponent extends Vue {
  /**
   * 标题数据
   * @private
   * @returns string
   */
  @Prop({ type: Array, default: () => [] })
  private titleList: Array<any>;

  /**
   * 数据源
   * @private
   * @returns string
   */
  @Prop({ type: Array, default: () => [] })
  private dataList: Array<any>;

  /**
   * 变量集合
   * @protected
   * @returns any
   */
  protected state: any = {
    listHeight: [],
    categoryHeight: [],
    currentIndex: 0,
  };

  // 获取高度
  protected listHeight() {
    // 内容高度
    let items = document.getElementsByClassName("goods-category-item");
    let height = 0;
    for (let i = 0; i < items.length; i++) {
      this.state.listHeight.push(height);
      let offsetHeight = (items[i] as any).offsetHeight;
      height += offsetHeight;
    }

    // 分类高度
    let categorys = document.getElementsByClassName("goods-category-type li");

    for (let i = 0; i < items.length; i++) {
      let scrolltop = (items[i] as any).offsetTop;
      this.state.categoryHeight.push(scrolltop);
    }
  }

  // 钩子
  protected mounted() {
    setTimeout(() => {
      this.listHeight();
    }, 0);
  }

  // 点击分类
  protected onCategoryClick(index) {
    this.state.currentIndex = index;
    (document.getElementById("goods-category-list") as any).scrollTop = this.state.listHeight[index];
  }

  // 滚动数据
  protected onscroll() {
    let scrolltop = document.getElementById("goods-category-list").scrollTop;
    for (let i = 0; i < this.state.listHeight.length; i++) {
      if (scrolltop > this.state.listHeight[i] && scrolltop < this.state.listHeight[i + 1]) {
        this.state.currentIndex = i;
        (document.getElementById("goods-category-type") as any).scrollTop = this.state.categoryHeight[i - 1 || 0];
      }
    }
  }
}
