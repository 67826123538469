
import { Component, Vue } from "vue-property-decorator";
import { PackageService, WechatService, OrganizationService, supplierService } from "src/services";
import { Toast, Dialog } from "vant";
import { tools } from "src/utils";
import dayjs from "dayjs";
import globalConfig from "src/config/index";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate", // for vue-router 2.2+
]);

@Component
export default class PackageDetail extends Vue {
  /**
   * 是否显示弹框
   * @private
   * @returns boolean
   */
  private isShowDialog: boolean = false;

  /**
   * 显示日期
   * @private
   * @returns boolean
   */
  private showDate: boolean = false;

  /**
   * 展开与折叠
   * @private
   * @returns boolean
   */
  private activeNames: any = ["1"];

  /**
   * 默认隐藏
   * @private
   * @returns boolean
   */
  private isHidden: boolean = false;

  /**
   * 获取企业信息
   * @private
   * @returns any
   */
  private get optionsNumber(): any {
    if (this.isHidden) {
      return this.baseItemTypes.slice(0, 4);
    } else {
      return this.baseItemTypes;
    }
  }

  /**
   * 预约须知
   * @private
   * @returns boolean
   */
  private interpretOverlay: boolean = false;

  /**
   * 是否有加项
   * @private
   * @returns boolean
   */
  private hasOptionalItems: boolean = false;

  /**
   * 机构可用数量
   * @private
   * @returns boolean
   */
  private hospitalCount: number = 0;

  /**
   * 套餐总数量
   * @private
   * @returns boolean
   */
  public baseItemCount: number = 0;

  /**
   * 选中日期
   * @private
   * @returns any
   */
  private selectDate: any = "";

  /**
   * 选中日期时间段
   * @private
   * @returns any
   */
  private physicalTimePeriod: any = "";

  /**
   * 排期数据
   * @private
   * @returns any
   */
  private schedulingData: any = [];

  /**
   * 标签列表
   * @private
   * @returns boolean
   */
  private tagList = [];

  /**
   * 排期数据
   * @private
   * @returns any
   */
  private weekList: any = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];

  /**
   * 固定套餐项目
   * @private
   * @returns any
   */
  private baseItemTypes: any = [];

  /**
   * 套餐信息
   * @private
   * @returns any
   */
  private packageInfo: any = {};

  /**
   * 机构信息
   * @private
   * @returns any
   */
  private hospitalInfo: any = {};

  /**
   * 通知内容
   * @private
   * @returns any
   */
  private noticeInfo: any = {
    name: "",
    content: "",
  };

  /**
   * 搜索条件
   * @private
   * @returns any
   */
  private filter: any = {
    employerMemberId: null,
    packageId: null, // 套餐id
    hospitalId: null, // 医院Id
    cityName: null, // 城市名称
    longitude: null, // 经度
    latitude: null, // 纬度
  };

  /**
   * 固定项目打开id
   * @private
   * @returns any
   */
  private fixedCollapseActive: any = [];

  /**
   * 组件进来之前
   * @private
   * @returns void
   */
  private beforeRouteEnter(to, from, next): void {
    let appid = localStorage.getItem("appid");
    if (!tools.isAndroid() && `/${appid}${to.path}` !== location.pathname) {
      location.assign(`/${appid}${to.fullPath}`);
    } else {
      next();
    }
  }

  // 手指可触摸滑动
  protected mounted() {
    let list = document.querySelector(".emoji-scrollbar");
    list.addEventListener("touchmove", (e) => e.stopPropagation(), false);
  }

  /**
   * 我知道了
   * @private
   * @returns void
   */
  private async onKnow() {
    this.interpretOverlay = false;
  }

  /**
   * 展开全部
   * @private
   * @returns void
   */
  public expandAll() {
    this.isHidden = !this.isHidden;
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 权益id
   * @private
   * @returns string
   */
  private get rightsId(): string {
    return (this.$route.query && (this.$route.query.rightsId as string)) || "";
  }

  /**
   * 医院/机构id
   * @private
   * @returns string
   */
  private get hospitalId(): string {
    return (this.$route.query && (this.$route.query.hospitalId as string)) || "";
  }

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get selectUserId(): any {
    let selectUserStorage = localStorage.getItem("selectUser");

    if (selectUserStorage) {
      return JSON.parse(selectUserStorage).employerMemberId || this.userInfo.userId;
    }

    return this.userInfo.userId;
  }

  /**
   * 套餐id
   * @private
   * @returns string
   */
  private get packageId(): string {
    return (this.$route.query && (this.$route.query.id as string)) || "";
  }

  /**
   * 当前位置
   * @private
   * @returns any
   */
  private get location(): any {
    return this.$store.getters.location || {};
  }

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get userInfo(): any {
    return this.$store.getters.userInfo || {};
  }

  /**
   * 切换机构
   * @private
   * @returns void
   */
  private onChangeOrg(): void {
    let query: any = { packageId: this.packageId };
    if (this.rightsId) {
      query.rightsId = this.rightsId;
    }
    this.$router.push({ name: "orgList", query: query });
  }

  /**
   * 确认日期
   * @private
   * @returns void
   */
  private onDateConfirm(date: any, period: any): void {
    if (date) {
      this.selectDate = dayjs(date).format("YYYY-MM-DD");
      this.physicalTimePeriod = period;
    }

    this.showDate = false;
    this.onNext();
  }

  /**
   * 下一步
   * @private
   * @returns void
   */
  private async onNext(): Promise<void> {
    try {
      if (this.hasOptionalItems) {
        localStorage.removeItem("selectOptionalItem");
        // 加项页面
        this.$router.push({
          name: "optional-package",
          query: {
            id: this.packageId,
            date: this.selectDate,
            physicalTimePeriod: this.physicalTimePeriod,
            hospitalId: this.hospitalInfo.id,
          },
        });
      } else {
        // 提交页面
        this.$router.push({
          name: "submit-order",
          query: {
            packageId: this.packageId,
            date: this.selectDate,
            physicalTimePeriod: this.physicalTimePeriod,
            hospitalId: this.hospitalInfo.id,
          },
        });
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 获取某个机构排期
   * @private
   * @returns void
   */
  private async getMedicalOrgDateInfo(): Promise<void> {
    try {
      let { content: result } = await OrganizationService.instance.getMedicalOrgDateInfo({
        packageId: this.packageId,
        hospitalId: this.hospitalInfo.id,
      });

      if (result.data) {
        let schedules = result.data || [];

        let schedulesObj = {};
        schedules.forEach((item) => {
          schedulesObj[dayjs(item.startTime).format("YYYY-MM-DD")] = item;
        });

        this.handlerCalendar(schedulesObj);
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 跳转地图
   * @private
   * @returns void
   */
  private async openMap(hospitalInfo: any): Promise<any> {
    if (hospitalInfo) {
      let lat = hospitalInfo.latitude;
      let lng = hospitalInfo.longitude;
      let address = hospitalInfo.address;
      let orgName = hospitalInfo.name;

      if (await tools.isWechat()) {
        let currentUrl = location.href;
        let { content: result } = await WechatService.instance.getWechatJSSDK(currentUrl);
        if (result.data) {
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: result.data.appId, // 必填，公众号的唯一标识
            timestamp: result.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
            signature: result.data.signature, // 必填，签名
            jsApiList: ["openLocation"], // 必填，需要使用的JS接口列表
          });

          wx.ready(function () {
            wx.openLocation({
              latitude: parseFloat(lat), // 纬度，范围为-90~90，负数表示南纬
              longitude: parseFloat(lng), // 经度，范围为-180~180，负数表示西经
              scale: 14, // 缩放比例
              name: orgName,
              address: address, // 地图缩放级别,整形值,范围从1~28。默认为最大
              infoUrl: "",
            });
          });
        }
      } else {
        // 腾讯地图
        // let href = `https://apis.map.qq.com/uri/v1/marker?marker=coord:${lat},${lng};addr:${this.orgDetailInfo.org_name}`;
        // 高德
        let href = `https://uri.amap.com/marker?position=${lng},${lat}&name=${orgName}&coordinate=gaode&callnative=1`;
        window.open(href);
      }
    }
  }

  /**
   * 组件创建
   * @private
   * @returns void
   */
  private created(): void {
    if (this.hospitalId) {
      this.filter.hospitalId = this.hospitalId;
    }

    if (this.location) {
      this.filter.cityName = this.location.city;
      this.filter.latitude = this.location.lat;
      this.filter.longitude = this.location.lng;
    }

    this.filter.packageId = this.packageId;
    this.filter.employerMemberId = this.selectUserId;

    this.getPackageDetail(this.filter);
  }

  /**
   * 显示日历空间日期
   * @private
   * @returns void
   */
  private handlerCalendar(schedulesObj: any): void {
    let dateList = [];
    // 获取下个月最后一天
    // let nextMonthDay = dayjs().add(1, "month").endOf('month');
    // 获取间隔天数
    // let intervalDay = dayjs(nextMonthDay).diff(dayjs(),'day');

    for (let i = 0; i < 60; i++) {
      let current = dayjs().add(i, "day").format("YYYY-MM-DD");

      let num = 0;
      let surplusType = 1;
      let currenObj = schedulesObj[current];
      if (currenObj) {
        num = currenObj.personCount;
        surplusType = currenObj.surplusType;
      }
      let obj = {
        date: current,
        num: num,
        surplusType: surplusType,
        txt: dayjs(current).format("MM.DD"),
        week: this.weekList[dayjs(current).day()],
      };
      dateList.push(obj);
    }
    this.schedulingData = dateList;
  }

  /**
   * 获取套餐详情
   * @private
   * @returns void
   */
  private async getPackageDetail(data: any): Promise<void> {
    try {
      let { content: result } = await PackageService.instance.getPackageInfo(data);

      if (result.data) {
        this.baseItemTypes = result.data.baseItemTypes;
        this.packageInfo = result.data.packageInfo;
        this.hospitalInfo = result.data.hospitalInfo;
        this.hasOptionalItems = result.data.hasOptionalItems;
        this.hospitalCount = result.data.hospitalCount;
        this.baseItemCount = result.data.baseItemCount;

        // 处理默认选择
        this.baseItemTypes &&
          this.baseItemTypes.forEach((item, index) => {
            this.fixedCollapseActive.push(index);
          });

        this.getMedicalOrgDateInfo();
        this.getApptNotice(this.hospitalInfo.id);
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 显示提示
   * @private
   * @returns void
   */
  private isShowNotice(): void {
    this.interpretOverlay = true;
  }

  /**
   * 预约详情
   * @private
   * @returns void
   */
  private async getApptNotice(id) {
    try {
      let { content: result } = await supplierService.instance.getApptNotice(id);
      if (result.data) {
        this.noticeInfo = result.data;
        this.noticeInfo.content = result.data.content.replace(/\n/gm, "<br/>");
        this.isShowNotice();
      }
    } catch (error) {
      Toast(error);
    }
  }

  /**
   * 选择日期
   * @private
   * @returns void
   */
  private onSelectDate(item: any, index: number): void {
    this.showDate = true;
    if (item.num > 0) {
      this.selectDate = item.date;
    }
  }

  /**
   * 获取企业参数配置
   * @private
   * @returns any
   */
  private get generalSettings(): any {
    let generalSettingsValue = localStorage.getItem("generalSettings");
    if (generalSettingsValue) {
      return JSON.parse(generalSettingsValue);
    }
    return [];
  }

  /**
   * 是否显示配置
   * @protected
   * @returns boolean
   */
  protected showStting(code: string): boolean {
    // A1001 -- 体检套餐列表页、详情页、提交订单页及订单详情页均不显示体检套餐价格及企业报销金额。
    // A1002 -- 体检套餐列表页、详情页均不显示体检套餐划线价格。
    let data = this.generalSettings.filter((res) => res.code == code);
    if (data.length > 0) {
      return data[0].isOpen;
    } else {
      return true;
    }
  }
}
